@tailwind base;
@tailwind components;
@tailwind utilities;
@import "leaflet/dist/leaflet.css";


body {
  margin: 0;
  padding: 0;
  font-family: Montserrat;
}
.sticky{
  position: sticky !important;
}
* {
  box-sizing: border-box;
  line-height: normal;
  font-family: inherit;
  margin: unset;
}

a {
  text-decoration: none;
  display: block;
}

ul {
  margin: 0;
  margin-inline: unset !important;
  padding: 0;
  list-style: none;
}

input {
  background-color: transparent;
  width: 100%;
}
.txrt input[type="checkbox"]:checked::before {
  content: '✔';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;  /* Adjust the size of the checkmark */
  color: white;  /* White checkmark */
}

input:focus {
  outline: none;
}
@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}
/* In your CSS file or within a <style> tag */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Adjust width as needed */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Light gray track color */
  border-radius: 10px; /* Rounded corners for the track */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #34A853; /* Green thumb color */
  border-radius: 10px; /* Rounded corners for the thumb */
}

@layer base {
  :root {
      /*-------- Color variables --------*/
      --amber_a400: #ffcc00;
      --black_900: #000000;
      --blue_gray_100: #d0ddd0;
      --blue_gray_100_01: #d9d9d9;
      --blue_gray_100_02: #d1d1d1;
      --blue_gray_300: #98a1b2;
      --blue_gray_400: #81888c;
      --blue_gray_50: #ebedf0;
      --blue_gray_500: #6e7c86;
      --blue_gray_600: #656380;
      --blue_gray_600_01: #575a88;
      --blue_gray_800: #255548;
      --blue_gray_800_01: #3f3d56;
      --blue_gray_900: #294437;
      --blue_gray_900_01: #263d42;
      --blue_gray_900_02: #1d2838;
      --blue_gray_900_03: #172a3a;
      --blue_gray_900_04: #263238;
      --cyan_800: #25788e;
      --gray_100: #eefaf7;
      --gray_300: #dadada;
      --gray_400_3f: #bbc0ba3f;
      --gray_50: #f8fafb;
      --gray_800: #3c354c;
      --gray_800_01: #3d3d3d;
      --gray_900: #0f1728;
      --green_100: #d0ddcf;
      --green_200: #92e3a9;
      --green_400: #65bc6a;
      --green_700: #398f3e;
      --green_800: #29752b;
      --green_900: #236823;
      --green_900_01: #134611;
      --orange_200: #f8ce6d;
      --orange_200_01: #ffce72;
      --orange_a200: #faaa41;
      --pink_100: #ffc0c6;
      --red_200: #eaa3ab;
      --teal_100: #b9d4db;
      --teal_300: #409cb5;
      --teal_300_01: #47acc4;
      --teal_300_02: #3fc0c6;
      --yellow_500: #fec731;
      --yellow_700: #fdc731;
      --yellow_700_01: #ffc43d;

      /*-------- Shadow variables --------*/
      --shadow-xs: 0 2px 16px 4px #bbc0ba3f;

      /*-------- Border radius variables --------*/
      --radius-xs: 16px;
      --radius-sm: 22px;
      --radius-md: 26px;
      --radius-lg: 38px;
      --radius-xl: 40px;
      --radius-2xl: 48px;
      --radius-3xl: 54px;



      /*-------- Spacing variables --------*/
      --space-xs: 4px;
      --space-sm: 10px;
      --space-md: 12px;
      --space-lg: 20px;
      --space-xl: 24px;
      --space-2xl: 28px;
      --space-3xl: 42px;
      --space-4xl: 74px;

  }
}
