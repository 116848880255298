@font-face {
    font-display: swap;
    font-style: normal;
    src: url("./fonts/MontserratRegular.ttf");
    font-family: "Montserrat";
    font-weight: 400;
}

@font-face {
    font-display: swap;
    font-style: normal;
    src: url("./fonts/MontserratMedium.ttf");
    font-family: "Montserrat";
    font-weight: 500;
}

@font-face {
    font-display: swap;
    font-style: normal;
    src: url("./fonts/MontserratBold.ttf");
    font-family: "Montserrat";
    font-weight: 700;
}

@font-face {
    font-display: swap;
    font-style: normal;
    src: url("./fonts/MontserratSemiBold.ttf");
    font-family: "Montserrat";
    font-weight: 600;
}

@font-face {
    font-display: swap;
    font-style: normal;
    src: url("./fonts/InterRegular.ttf");
    font-family: "Inter";
    font-weight: 400;
}